import * as React from "react";
import Layout from "../components/layout";


const Terms = () => {
  return (
    <Layout>
      <section>
      </section>
    </Layout>
  );
};

export default Terms;
